import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DriverOnboardingActions } from '../../store';

@Component({
  selector: 'app-rejection-confirmation',
  templateUrl: 'onboarding-rejection.component.html',
  styleUrls: ['onboarding-rejection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingRejectionComponent {

  constructor(private store: Store) { }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }
}
