@if (loading$ | async) {
  <mat-progress-bar mode="indeterminate" class="progress-bar"></mat-progress-bar>
}
<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="content">
    <div class="header">Let's start with an introduction!</div>
    <iframe [src]="iframeSrc$ | async"
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;" class="iframe">
    </iframe>
  </div>
</div>
