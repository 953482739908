@if (isLoading()) {
<div class="custom-file-upload">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
}

@if(fileType() === 'image/png, image/jpeg') {
@if(!isLoading() && !imgUrl()) {
<label class="custom-file-upload">
  <input accept="image/png, image/jpeg" type="file" (change)="onAddFile($event)">
  <i class="icon material-icons">upload_file</i>
  <div class="upload-text">
    {{ uploadText() }}
  </div>
</label>
}

@if (!isLoading() && imgUrl()) {
<label>
  <input accept="image/png, image/jpeg" type="file" (change)="onAddFile($event)">
  <div class="uploaded-image-container">
    <i class="icon material-icons">upload_file</i>
    <img class="img" [src]="imgUrl()">
  </div>
</label>
}
}

@if(fileType() === 'application/pdf') {
@if (!isLoading() && !fileName()) {
<label class="custom-file-upload">
  <input accept="application/pdf" type="file" (change)="onAddFile($event)">
  <i class="icon material-icons">upload_file</i>
  <div class="upload-text">
    {{ uploadText() }}
  </div>
</label>
}

@if (!isLoading() && fileName()) {
<label>
  <input #fileInput accept="application/pdf" type="file" (change)="onAddFile($event)">
  <div class="uploaded-image-container">
    <i class="icon material-icons">upload_file</i>
    <div class="uploaded-file-container">
      <div>
        Criminal record uploaded
      </div>
      <div>(Click again to upload a different file)</div>
    </div>

  </div>
</label>
}
}