<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="middle-section">
    <div>
      <div class="header">Unfortunately, your profile has been rejected by our team.</div>
      <div class="text">This could be due to many reasons, such as invalid information or failed e-learnings. For
        more information, please contact us via chat button below</div>
    </div>
  </div>
</div>
