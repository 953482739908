import { NgOptimizedImage } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { NotificationService } from 'src/app/core/services';
import { ImageUploadComponent } from 'src/app/shared/components/image-upload/image-upload.component';
import { FirebaseStorageService } from 'src/app/shared/services/firebase-storage.service';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
  selector: 'app-onboarding-criminal-record',
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    ImageUploadComponent,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    NgOptimizedImage
  ],
  templateUrl: './onboarding-criminal-record.component.html',
  styleUrl: './onboarding-criminal-record.component.scss'
})
export class OnboardingCriminalRecordComponent implements OnInit {
  form: FormGroup | undefined;
  fb: FormBuilder;
  store: Store;
  destroyRef: DestroyRef;
  notificationService: NotificationService;
  fireStorageService: FirebaseStorageService;
  uploadingCriminalRecordFile: Signal<boolean>;
  blob: SafeResourceUrl | undefined;
  sanitizer: DomSanitizer;

  constructor() {
    this.store = inject(Store);
    this.fb = inject(FormBuilder);
    this.destroyRef = inject(DestroyRef);
    this.notificationService = inject(NotificationService);
    this.fireStorageService = inject(FirebaseStorageService);
    this.sanitizer = inject(DomSanitizer);
    this.uploadingCriminalRecordFile = this.store.selectSignal(DriverOnboardingSelectors.selectUploadingCriminalRecordFile);
  }

  ngOnInit(): void {
    this.createForm();
    this.subscribeToOnboardingStateChanges();
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  onContinueClick() {
    if (this.form?.valid) {
      this.store.dispatch(DriverOnboardingActions.criminalRecordContinueClicked());
    } else {
      this.notificationService.error('Both criminal record pages are required');
    }
  }

  onBackClick() {
    this.store.dispatch(DriverOnboardingActions.criminalRecordBackClicked());
  }

  onCriminalRecordFileUpload(pdfSource: string) {
    if (pdfSource) {
      this.store.dispatch(DriverOnboardingActions.criminalRecordFileUploaded({ pdfSource }));
    }
  }

  private subscribeToOnboardingStateChanges() {
    this.store.select(DriverOnboardingSelectors.selectDriverOnboarding).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(driverOnboarding => !!driverOnboarding),
    ).subscribe(driverOnboarding => {
      this.form?.patchValue({
        criminalRecordFileName: driverOnboarding?.criminal_record_file_name,
      });
      driverOnboarding?.criminal_record_file_name && this.fireStorageService.getBlob(`driver/${driverOnboarding.driver_id}/onboarding-criminal-record-file.pdf`).then(blob => {
        this.blob = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
      });
    });
  }

  private createForm() {
    this.form = this.fb.group({
      criminalRecordFileName: ['', Validators.required]
    });
  }
}
