<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="middle-section">
    <div>
      <div class="header">Upload your photo</div>
      <div class="description">Please take a photo. It will be used to match the taxi and driver's license. Requirements
        for a photo:</div>
      <div class="text">1. White background</div>
      <div class="text">2. Good lightning</div>
      <div class="text">3. Face only</div>
      <div class="text">4. No additional accessories (hats, necklaces, etc.)</div>
      <div class="form-container">
        <form [formGroup]="form">
          <div class="image-upload-container">
            <div class="image-upload-row">
              <app-image-upload [uploadText]="'Upload photo'" [imgUrl]="form.controls.profilePhoto.value"
                (fileUploaded)="onPhotoImageUpload($event)" [isLoading]="uploadingDriverPhoto$ | async">
              </app-image-upload>
            </div>
            <div class="example-container">
              <div class="photo-description">Example</div>
              <img class="photo" src="/assets/images/driver_example_photo.jpg">
            </div>
          </div>
          <div class="buttons-container">
            <button mat-raised-button (click)="onBackClick()" [disabled]="(uploadingDriverPhoto$ | async)">Back</button>
            <button color="accent" type="submit" mat-raised-button (click)="onContinueClick()"
              [disabled]="!form.valid || (uploadingDriverPhoto$ | async)">Continue</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>