import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject, combineLatest, filter, map, shareReplay, takeUntil } from 'rxjs';
import { Area } from 'src/app/core/models/firestore.model';
import { environment } from 'src/environments/environment';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
  selector: 'app-onboarding-driving-info',
  templateUrl: 'onboarding-driving-info.component.html',
  styleUrls: ['onboarding-driving-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingDrivingInfoComponent implements OnInit, OnDestroy {
  private selectedAreaId$: Observable<string | null> = EMPTY;

  areas$: Observable<Area[]> = EMPTY;
  form = this.formBuilder.group({
    areaId: new FormControl('', [Validators.required]),
    expiresAt: new FormControl<Date | null | ''>('', Validators.required),
    licenseNumber: new FormControl('', [Validators.required]),
    taxiLicenseFrontPhotoUrl: new FormControl('', Validators.required),
    taxiLicenseBackPhotoUrl: new FormControl('', Validators.required)
  }, { validators: this.validateForm.bind(this) });
  uploadingLicenseFront$: Observable<boolean> = EMPTY;
  uploadingLicenseBack$: Observable<boolean> = EMPTY;
  destroyed$ = new Subject<void>();
  savingDrivingInfo$: Observable<boolean> = EMPTY;
  taxiLicenseBackPhotoUrlHidden$: Observable<boolean> = EMPTY;
  minDate = new Date();
  maxDate = new Date('2099-12-31');
  numberFieldName$: Observable<string> = EMPTY;
  norwaySelected$: Observable<boolean> = EMPTY;
  areas: Area[] | undefined;
  selectedAreaCountryCode$: Observable<string> = EMPTY;
  licenseExamplePhotoSrc$: Observable<string | undefined | null> = EMPTY;
  isLicenseExampleShown$: Observable<boolean> = EMPTY;

  constructor(private store: Store, private formBuilder: FormBuilder) { }

  ngOnInit() {
    // prevents a bug where the form is not updated when the areaId is initialized from backend
    // should not be necessary using the store, selectors, etc.
    this.selectedAreaId$ = this.form.controls.areaId.valueChanges.pipe(
      shareReplay(1),
    );
    this.selectedAreaId$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe();

    this.areas$ = this.store.select(DriverOnboardingSelectors.selectAreas).pipe(
      takeUntil(this.destroyed$),
      filter(areas => !!areas),
      // in prod show only Copenhagen and Aarhus
      map(a => environment.production ? a.filter(a => a.name === 'Copenhagen' || a.name === 'Aarhus') : a)
    );
    this.areas$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(areas => this.areas = areas);
    this.uploadingLicenseFront$ = this.store.select(DriverOnboardingSelectors.selectIsUploadingTaxiLicenseFrontPhoto);
    this.uploadingLicenseBack$ = this.store.select(DriverOnboardingSelectors.selectIsUploadingTaxiLicenseBackPhoto);
    this.savingDrivingInfo$ = this.store.select(DriverOnboardingSelectors.selectIsSavingOnboardingDrivingInfo);
    this.selectedAreaCountryCode$ = combineLatest([
      this.selectedAreaId$,
      this.areas$
    ]).pipe(
      filter((([areaId, areas]) => !!areaId && !!areas && areas.length > 0)),
      map((([areaId, areas]) => areas.find(areas => areas.id === areaId)?.country_code || ''))
    );
    this.numberFieldName$ = this.selectedAreaCountryCode$.pipe(
      map(areaCountryCode => areaCountryCode === 'NO' ? 'Social security number' : 'Taxi license number')
    );
    this.licenseExamplePhotoSrc$ = this.selectedAreaCountryCode$.pipe(
      takeUntil(this.destroyed$),
      map(areaCountryCode => {
        switch (areaCountryCode) {
          case 'NO':
            return '/assets/images/no_driver_license.jpg';
          case 'DK':
            return '/assets/images/dk_driver_license.jpg';
          case 'LT':
            return null;
          default:
            return null;
        }
      })
    );
    this.isLicenseExampleShown$ = this.licenseExamplePhotoSrc$.pipe(
      takeUntil(this.destroyed$),
      map(licenseExamplePhotoSrc => !!licenseExamplePhotoSrc)
    );
    this.store.select(DriverOnboardingSelectors.selectDriverOnboardingVM).pipe(
      takeUntil(this.destroyed$),
      filter(driverOnboarding => !!driverOnboarding)
    ).subscribe(driverOnboarding => {
      this.form.patchValue({
        areaId: this.form.controls.areaId.value || driverOnboarding?.areaId,
        expiresAt: this.form.controls.expiresAt.value || driverOnboarding?.taxiLicenseExpiresAt || '',
        licenseNumber: this.form.controls.licenseNumber.value || driverOnboarding?.taxiLicenseNumber,
        taxiLicenseFrontPhotoUrl: driverOnboarding?.taxiLicenseFrontPhotoUrl,
        taxiLicenseBackPhotoUrl: driverOnboarding?.taxiLicenseBackPhotoUrl
      });
    });
  }

  onTaxiLicenseFrontUpload(imageSource: string) {
    this.store.dispatch(DriverOnboardingActions.taxiLicenseFrontUploaded({ imageSource }));
  }

  onTaxiLicenseBackUpload(imageSource: string) {
    this.store.dispatch(DriverOnboardingActions.taxiLicenseBackUploaded({ imageSource }));
  }

  onContinueClick() {
    // should be prevented by validation
    if (!this.form.controls.expiresAt.value) {
      throw new Error('Expiration date is required');
    }

    this.store.dispatch(DriverOnboardingActions.drivingInfoContinueClicked({
      areaId: this.form.controls.areaId.value || '',
      expiresAt: this.form.controls.expiresAt.value.getTime(),
      licenseNumber: this.form.controls.licenseNumber.value || ''
    }));
  }

  onBackClick() {
    this.store.dispatch(DriverOnboardingActions.drivingInfoBackClicked());
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  validateForm(form: AbstractControl): ValidationErrors | null {
    const areaIdControl = form.get('areaId');
    const licenseNumberControl = form.get('licenseNumber');

    if (areaIdControl && licenseNumberControl) {
      if (areaIdControl.value && this.areas?.find(areas => areas.id === areaIdControl?.value)?.country_code === 'NO' && licenseNumberControl.value && !(/^\d{11}$/.test(licenseNumberControl.value))) {
        licenseNumberControl.markAsTouched();
        licenseNumberControl.setErrors({ licenseNumberInvalid: true });
      } else {
        if (licenseNumberControl && licenseNumberControl.hasError('licenseNumberInvalid')) {
          delete licenseNumberControl.errors?.['licenseNumberInvalid'];
          licenseNumberControl.updateValueAndValidity();
        }
      }
    }
    return null;
  }
}
