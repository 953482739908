
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinnerModule],
  standalone: true
})
export class ImageUploadComponent {
  fileType = input<string>('image/png, image/jpeg');
  isLoading = input<boolean | null>(null);
  uploadText = input<string>('');
  imgUrl = input<string | null>(null);
  fileName = input<string | null>(null);

  fileUploaded = output<string>();

  onAddFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onloadend = () => {
        this.fileUploaded.emit(fileReader.result as string);
      };
    }
  }
}
