@if (savingDrivingInfo$ | async) {
  <mat-progress-bar mode="indeterminate" class="progress-bar"></mat-progress-bar>
}
<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="middle-section">
    <div class="container">
      <div class="header">Fill in your chauffeur information</div>
      <form [formGroup]="form" class="form">
        <div class="area-group">
          <div>Where are you going to drive in?</div>
          <mat-form-field class="area-select" appearance="outline">
            <mat-label>Area</mat-label>
            <mat-select formControlName="areaId" required>
              @for (area of (areas$ | async); track area) {
                <mat-option [value]="area.id">
                  {{area.name}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div [hidden]="isLicenseExampleShown$ | async">
          <div class="image-upload-container">
            <div class="image-upload-row">
              <app-image-upload [uploadText]="'Upload taxi license front'"
                [imgUrl]="form.controls.taxiLicenseFrontPhotoUrl.value"
                (fileUploaded)="onTaxiLicenseFrontUpload($event)" [isLoading]="uploadingLicenseFront$ | async">
              </app-image-upload>
              <app-image-upload [uploadText]="'Upload taxi license back'"
                [imgUrl]="form.controls.taxiLicenseBackPhotoUrl.value" (fileUploaded)="onTaxiLicenseBackUpload($event)"
                [isLoading]="uploadingLicenseBack$ | async">
              </app-image-upload>
            </div>
          </div>
        </div>
        <div [hidden]="(isLicenseExampleShown$ | async) === false">
          <div class="image-upload-container">
            <div class="image-upload-row">
              <app-image-upload [uploadText]="'Upload taxi license front'"
                [imgUrl]="form.controls.taxiLicenseFrontPhotoUrl.value"
                (fileUploaded)="onTaxiLicenseFrontUpload($event)" [isLoading]="uploadingLicenseFront$ | async">
              </app-image-upload>
              <app-image-upload [uploadText]="'Upload taxi license back'"
                [imgUrl]="form.controls.taxiLicenseBackPhotoUrl.value" (fileUploaded)="onTaxiLicenseBackUpload($event)"
                [isLoading]="uploadingLicenseBack$ | async">
              </app-image-upload>
            </div>
            <div class="example-container">
              <div class="photo-description">Example</div>
              <img class="photo" [src]="licenseExamplePhotoSrc$ | async">
            </div>
          </div>
        </div>

        <div class="date-group">
          <mat-form-field appearance="outline" class="field">
            <mat-label>Expiry date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="expiresAt" autocomplete="off" [min]="minDate"
              [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            @if (form.controls.expiresAt.hasError('required')) {
              <mat-error>
                <strong>Required</strong>
              </mat-error>
            }
            @if (form.controls.expiresAt.hasError('matDatepickerMin') || form.controls.expiresAt.hasError('matDatepickerMax')) {
              <mat-error
                >
                <strong>Invalid date</strong>
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field class="field" appearance="outline">
            <mat-label class="label">{{numberFieldName$ | async}}</mat-label>
            <input matInput formControlName="licenseNumber">
            @if (form.controls.licenseNumber.hasError('required')) {
              <mat-error>
                <strong>Required</strong>
              </mat-error>
            }
            @if (form.controls.licenseNumber.hasError('licenseNumberInvalid')) {
              <mat-error>
                <strong>Must contain 11 digits</strong>
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="buttons-container">
          <button mat-raised-button (click)="onBackClick()"
          [disabled]="(savingDrivingInfo$ | async) || (uploadingLicenseFront$ | async) || (uploadingLicenseBack$ | async)">Back</button>
          <button color="accent" type="submit" mat-raised-button (click)="onContinueClick()"
          [disabled]="!form.valid || (savingDrivingInfo$ | async) || (uploadingLicenseFront$ | async) || (uploadingLicenseBack$ | async)">Continue</button>
        </div>
      </form>
    </div>
  </div>
</div>
