@if (savingDriverLicenseInfo()) {
<mat-progress-bar mode="indeterminate" class="progress-bar"></mat-progress-bar>
}
<div class="container">
    <div class="header">
        <img class="logo" src="/assets/images/viggo_logo_black.png">
        <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
    </div>
    <div class="middle-section">
        <div class="container">
            <div class="header">Fill in driver's license information</div>
            @if(form) {
            <form [formGroup]="form" class="form">

                <div>
                    <div class="image-upload-container">
                        <div class="image-upload-row">
                            <app-image-upload [uploadText]="'Upload driver&rsquo;s license front'"
                                [imgUrl]="form.controls['driverLicenseFrontPhotoUrl'].value"
                                (fileUploaded)="onDriverLicenseFrontUpload($event)"
                                [isLoading]="uploadingDriverLicenseFront()">
                            </app-image-upload>
                            <app-image-upload [uploadText]="'Upload driver&rsquo;s license back'"
                                [imgUrl]="form.controls['driverLicenseBackPhotoUrl'].value"
                                (fileUploaded)="onDriverLicenseBackUpload($event)"
                                [isLoading]="uploadingDriverLicenseBack()">
                            </app-image-upload>
                        </div>
                        <div class="example-container">
                            <div class="photo-description">Example</div>
                            <div class="photo">
                                <img ngSrc="/assets/images/driver_license_example.png" fill>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="date-group">
                    <mat-form-field appearance="outline" class="field">
                        <mat-label>Expiry date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="driverLicenseExpiresAt"
                            autocomplete="off" [min]="minDate" [max]="maxDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        @if (form.controls['driverLicenseExpiresAt'].hasError('required')) {
                        <mat-error>
                            <strong>Required</strong>
                        </mat-error>
                        }
                        @if (form.controls['driverLicenseExpiresAt'].hasError('matDatepickerMin') ||
                        form.controls['driverLicenseExpiresAt'].hasError('matDatepickerMax')) {
                        <mat-error>
                            <strong>Invalid date</strong>
                        </mat-error>
                        }
                    </mat-form-field>
                    <mat-form-field class="field" appearance="outline">
                        <mat-label class="label">License number</mat-label>
                        <input matInput formControlName="driverLicenseNumber">
                        @if (form.controls['driverLicenseNumber'].hasError('required')) {
                        <mat-error>
                            <strong>Required</strong>
                        </mat-error>
                        }
                        @if (form.controls['driverLicenseNumber'].hasError('licenseNumberInvalid')) {
                        <mat-error>
                            <strong>Must contain 11 digits</strong>
                        </mat-error>
                        }
                    </mat-form-field>
                </div>
                <div class="buttons-container">
                    <button mat-raised-button [disabled]="backButtonDisabled()" (click)="onBackClick()">Back</button>
                    <button color="accent" type="submit" mat-raised-button
                        [disabled]="backButtonDisabled() || !form.valid" (click)="onContinueClick()">Continue</button>
                </div>
            </form>
            }
        </div>
    </div>
</div>