import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthSelectors } from 'src/app/auth/store';
import { PlatformSetting } from 'src/app/core/models/firestore.model';
import { AppSelectors } from 'src/app/store';
import { DriverOnboardingVM } from '../models/driver-onboarding.vm';
import * as fromDriverOnboarding from './driver-onboarding.reducer';

export const selectDriverOnboardingState = createFeatureSelector<fromDriverOnboarding.State>(
  fromDriverOnboarding.driverOnboardingFeatureKey
);
export const selectIsUploadingDriverPhoto = createSelector(selectDriverOnboardingState, state => state.uploadingDriverPhoto);
export const selectIsUploadingTaxiLicenseBackPhoto = createSelector(selectDriverOnboardingState, state => state.uploadingTaxiLicenseBackPhoto);
export const selectIsUploadingTaxiLicenseFrontPhoto = createSelector(selectDriverOnboardingState, state => state.uploadingTaxiLicenseFrontPhoto);
export const selectIsSavingOnboardingPersonalInfo = createSelector(selectDriverOnboardingState, state => state.savingOnboardingPersonalInfo);
export const selectIsSavingOnboardingDrivingInfo = createSelector(selectDriverOnboardingState, state => state.savingOnboardingDrivingInfo);

// DriverOnboarding
export const selectDriverOnboarding = createSelector(AuthSelectors.selectDriverAggregate, driverAggregate => driverAggregate?.onboarding);
export const selectIntroductionVideoUrl = createSelector(selectDriverOnboarding, onboarding => onboarding?.introduction_video_url);
export const selectDriverOnboardingAreaId = createSelector(selectDriverOnboarding, driverOnboarding => driverOnboarding?.area_id);
export const selectDriverOnboardingMetaTs = createSelector(selectDriverOnboarding, onboarding => onboarding?.meta_ts);
export const selectOnboardingExamCooldownDuration = createSelector(AppSelectors.selectSettings, selectDriverOnboardingAreaId, (settings, areaId) =>
  settings && areaId ? getSettingValueByAreaId(areaId, settings['driver.onboarding_exam_cooldown_duration']) as number : null);
export const selectDriverOnboardingExamCooldownEndAt = createSelector(selectDriverOnboarding, selectOnboardingExamCooldownDuration,
  (onboarding, examCooldownDuration) =>
    onboarding?.last_exam_completed_at && examCooldownDuration
      ? onboarding.last_exam_completed_at + examCooldownDuration
      : null
);
export const selectDriverOnboardingExampleCooldownEndDate = createSelector(selectDriverOnboardingExamCooldownEndAt, examCooldownEndAt =>
  new Date(examCooldownEndAt || '')
);
export const selectIsDriverOnboardingOnCooldown = createSelector(selectDriverOnboarding, selectDriverOnboardingExamCooldownEndAt,
  (driverOnboarding, driverOnboardingExamCooldownEndDate) =>
    !driverOnboarding?.exam_passed_at && !!driverOnboardingExamCooldownEndDate && driverOnboardingExamCooldownEndDate >= new Date().getTime()
);
export const selectUploadingDriverLicenseFront = createSelector(selectDriverOnboardingState, state => state.uploadingDriverLicenseFrontPhoto);
export const selectUploadingDriverLicenseBack = createSelector(selectDriverOnboardingState, state => state.uploadingDriverLicenseBackPhoto);
export const selectUploadingCriminalRecordFile = createSelector(selectDriverOnboardingState, state => state.uploadingCriminalRecordFile);
export const selectSavingDriverLicenseInfo = createSelector(selectDriverOnboardingState, state => state.savingOnboardingDriverLicenseInfo);

// Areas
export const selectAreas = createSelector(AppSelectors.selectAreas, areas => areas);
export const selectDriverOnboardingVM = createSelector(selectDriverOnboarding, selectAreas, (driverOnboarding, areas) => {
  if (!driverOnboarding) {
    return null;
  }
  const areaCountryCode = areas.find(areas => areas.id === driverOnboarding.area_id)?.country_code;

  return {
    id: driverOnboarding.driver_id,
    firstName: driverOnboarding.first_name,
    lastName: driverOnboarding.last_name,
    phoneNumber: driverOnboarding.phone_number ? driverOnboarding.phone_number : getDialCode(areaCountryCode),
    areaId: driverOnboarding.area_id,
    taxiLicenseExpiresAt: driverOnboarding.taxi_license_expires_at ? new Date(driverOnboarding.taxi_license_expires_at) : null,
    taxiLicenseNumber: driverOnboarding.taxi_license_number,
    profilePhotoUrl: driverOnboarding.profile_photo_url,
    taxiLicenseFrontPhotoUrl: driverOnboarding.taxi_license_front_photo_url,
    taxiLicenseBackPhotoUrl: driverOnboarding.taxi_license_back_photo_url,
    driverLicenseFrontPhotoUrl: driverOnboarding.driver_license_front_photo_url,
    driverLicenseBackPhotoUrl: driverOnboarding.driver_license_back_photo_url,
    driverLicenseNumber: driverOnboarding.driver_license_number,
    driverLicenseExpiresAt: driverOnboarding.driver_license_expires_at ? new Date(driverOnboarding.driver_license_expires_at) : null,
    criminalRecordFileName: driverOnboarding.criminal_record_file_name,
  } satisfies DriverOnboardingVM;
});

function getDialCode(areaCountryCode: string | undefined) {
  switch (areaCountryCode) {
    case 'NO':
      return '+44';
    case 'DK':
      return '+45';
    case 'LT':
      return '+370';
    default:
      return '+45';
  }
}

function getSettingValueByAreaId(areaId: string, setting: PlatformSetting) {
  if (!setting || !setting.values || setting.values.length === 0) {
    return null;
  }
  const defaultSettingValue = setting.values.find(v => !v.condition);
  const settingValue = setting.values.find(v => v.condition?.value === areaId);
  return settingValue ? settingValue.value : defaultSettingValue?.value;
}
