<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="middle-section">
    <div>
      <div class="header">Profile created and exam passed!</div>
      <div class="text">Our team will review your profile. You will be notified by email when it's approved.</div>
      <div class="buttons-container">
        <button mat-raised-button (click)="onEditClick()">Edit profile</button>
      </div>
    </div>
  </div>
</div>
