<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="content">
    <div class="header">Last step! Exam.</div>
    <div id="typeform-container" class="typeform-container"></div>
  </div>
</div>
