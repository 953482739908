<div class="container">
    <div class="header">
        <img class="logo" src="/assets/images/viggo_logo_black.png">
        <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
    </div>
    <div class="middle-section">
        <div class="container">
            <div class="header">Upload criminal record</div>
            <div class="header-details">
                <p class="criminal-record-info">
                    For more information about criminal records, you can visit:
                </p>
                <a class="link"
                    href="https://politi.dk/service-og-tilladelser/straffeattest/bestil-privat-straffeattest"
                    target="_blank">https://politi.dk/service-og-tilladelser/straffeattest/bestil-privat-straffeattest</a>
                <div class="criminal-record-note">
                    <small><i>*It is important to emphasize that you are not automatically disqualified as a new Viggo
                            partner or
                            driver if
                            you have a criminal record. An individual assessment will always take place.</i></small>
                </div>
            </div>
            @if(form) {
            <form [formGroup]="form" class="form">
                <div class="image-upload-container">
                    <div class="image-upload-row">
                        <app-image-upload [uploadText]="'Upload criminal record'" fileType="application/pdf"
                            [fileName]="form.controls['criminalRecordFileName'].value"
                            (fileUploaded)="onCriminalRecordFileUpload($event)"
                            [isLoading]="uploadingCriminalRecordFile()">
                        </app-image-upload>
                    </div>
                    @if (blob) {
                    <div class="preview-container">
                        <div class="preview-header">Preview</div>
                        <embed [src]="blob" type="application/pdf" width="300px" height="270px">
                    </div>
                    }
                </div>

                <div class="preview-container example">
                    <div class="preview-header">Example</div>
                    <div class="photo">
                        <img ngSrc="/assets/images/criminal_record_example.png" alt="Criminal record example" fill
                            priority>
                    </div>
                </div>

                <div class="buttons-container">
                    <button mat-raised-button [disabled]="uploadingCriminalRecordFile()"
                        (click)="onBackClick()">Back</button>
                    <button color="accent" [disabled]="uploadingCriminalRecordFile() || !form.valid" type="submit"
                        mat-raised-button (click)="onContinueClick()">Continue</button>
                </div>
            </form>
            }
        </div>
    </div>
</div>