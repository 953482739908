import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthSelectors } from '../../../../auth/store';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
  selector: 'app-onboarding-elearning',
  templateUrl: 'onboarding-elearning.component.html',
  styleUrls: ['onboarding-elearning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingElearningComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<void>();

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private store: Store,
    private router: Router
  ) { }

  ngOnInit() {
    const script = this._renderer2.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    environment.typeformFormId;

    this._renderer2.appendChild(this._document.body, script);

    this.store.select(AuthSelectors.selectUserId).pipe(
      take(1)
    ).subscribe(id => {
      const typeformContainer = this._document.getElementById("typeform-container");
      typeformContainer?.setAttribute("data-tf-widget", environment.typeformFormId);
      typeformContainer?.setAttribute("data-tf-hidden", `user_id=${id}`);
    });

    this.store.select(DriverOnboardingSelectors.selectDriverOnboarding).pipe(
      withLatestFrom(this.store.select(DriverOnboardingSelectors.selectIsDriverOnboardingOnCooldown)),
      takeUntil(this.destroyed$),
    ).subscribe(([onboarding, driverOnCooldown]) => {
      if (!onboarding?.exam_passed_at) {
        if (driverOnCooldown) {
          this.router.navigateByUrl('/driver-onboarding/elearning/cooldown');
        }
      } else {
        this.router.navigateByUrl('/driver-onboarding/confirmation');
      }
    });
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
