import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subject, filter, takeUntil } from 'rxjs';
import { DriverOnboardingVM } from '../../models/driver-onboarding.vm';
import { DriverOnboardingActions, DriverOnboardingSelectors } from '../../store';

@Component({
  selector: 'app-onboarding-photo',
  templateUrl: 'onboarding-photo.component.html',
  styleUrls: ['onboarding-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingPhotoComponent implements OnInit, OnDestroy {

  form = new FormGroup({
    profilePhoto: new FormControl('', Validators.required),
  });
  uploadingDriverPhoto$: Observable<boolean> = EMPTY;
  destroyed$ = new Subject<void>();

  constructor(private store: Store) { }

  ngOnInit() {
    this.uploadingDriverPhoto$ = this.store.select(DriverOnboardingSelectors.selectIsUploadingDriverPhoto);
    this.store.select(DriverOnboardingSelectors.selectDriverOnboardingVM).pipe(
      takeUntil(this.destroyed$),
      filter(driverOnboarding => !!driverOnboarding)
    ).subscribe(driverOnboarding => {
      this.form.controls.profilePhoto.setValue((<DriverOnboardingVM>driverOnboarding).profilePhotoUrl);
    });
  }

  onPhotoImageUpload(imageSource: string) {
    this.store.dispatch(DriverOnboardingActions.driverPhotoUploaded({ imageSource }));
  }

  onContinueClick() {
    this.store.dispatch(DriverOnboardingActions.photoContinueClicked());
  }

  onBackClick() {
    this.store.dispatch(DriverOnboardingActions.photoBackClicked());
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
