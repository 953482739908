<div class="container">
  <div class="header">
    <img class="logo" src="/assets/images/viggo_logo_black.png">
    <button class="logout" mat-raised-button (click)="onLogoutClick()">Logout</button>
  </div>
  <div class="middle-section">
    <div>
      <div class="header">Unfortunately, you have failed an exam. Keep trying.</div>
      <div class="text">Although you were not successful with the exam this time, you have the opportunity to try
        again on {{(driverOnboardingExamCooldownEndDate$ | async) | date:'short'}}.</div>
      <div class="text">Take this time to reflect on what you can do to prepare and increase your chances of
        success.</div>
      <div class="buttons-container">
        <button mat-raised-button (click)="onEditClick()">Edit profile</button>
      </div>
    </div>
  </div>
</div>
