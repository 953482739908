import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { DriverOnboardingActions } from '../../store';

@Component({
  selector: 'app-onboarding-confirmation',
  templateUrl: 'onboarding-confirmation.component.html',
  styleUrls: ['onboarding-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingConfirmationComponent {

  constructor(private store: Store) { }

  onEditClick() {
    this.store.dispatch(DriverOnboardingActions.onboardingEditProfileClicked());
  }

  onLogoutClick() {
    this.store.dispatch(DriverOnboardingActions.logoutButtonClicked());
  }
}
